<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(statement.behavior)[0]}-${type}`).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="statement.behavior.viewInfoBlock" :statement="statement" :comments="comments")
    FormEdit(v-else-if="statement.behavior.viewEditBlock" :statement="statement")
    UploadFiles(v-else-if="statement.behavior.viewTransferBlock" :sailorDocument="statement")
    ViewPhotoList(
      v-else-if="statement.behavior?.viewFilesBlock"
      :files="statement.files"
      :sailorDocument="statement"
      :documentType="type"
      :deleteFile="removeFileRecordBookStatement"
      :editFile="updateFileServiceRecordBookStatement"
      isShowEdit
      )
    Delivery(:sailorDocument="statement" v-else-if="statement.behavior.viewDeliveryBlock")

</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '../../../configs/constants'

export default {
  name: 'SailorRecordBookStatementDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorRecordBookStatement/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorRecordBookStatement/FormEdit.vue'),
    UploadFiles: () => import('@/views/Sailor/SailorRecordBookStatement/UploadFiles.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    Delivery: () => import('./Delivary.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')

  },
  data () {
    return {
      type: 'recordBookStatement',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 9,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.statement, 'viewEditBlock'),
          checkAccess: () => checkAccess('recordBookStatement', 'edit', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-file-upload-outline',
          action: () => viewDetailedComponent(this.statement, 'viewTransferBlock'),
          checkAccess: () => checkAccess('recordBookStatement', 'transfer', this.statement) || checkAccess('recordBookStatement', 'add_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.add',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 8,
          name: 'mdi-truck-delivery-outline',
          action: () => viewDetailedComponent(this.statement, 'viewDeliveryBlock'),
          checkAccess: () => checkAccess('recordBookStatement', 'edit', this.statement) && !this.statement.morrichservice_office,
          color: 'green',
          tooltip: 'documentIconControl.delivery',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('recordBookStatement', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('recordBookStatement', 'delete', this.statement),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'experience-statements' }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['statementByID']),
    documentID () {
      return this.$route.params.documentID
    },
    ...mapState({
      statement: state => state.sailor.recordBookStatementById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['removeRecordBookStatement', 'getRecordBookStatementById', 'removeFileRecordBookStatement',
      'updateFileServiceRecordBookStatement']),
    async getData () {
      await this.getRecordBookStatementById({ ...this.$route.params })
    },

    deleteDocument () {
      deleteConfirmation(this).then(async confirmation => {
        if (confirmation) {
          const response = await this.removeRecordBookStatement({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$notification.success('deletedDocument')
            back('experience-statements')
          } else this.$notification.error('cantDeleteDoc')
        }
      })
    }
  }
}

</script>
